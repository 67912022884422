/* eslint-disable @shopify/jsx-no-hardcoded-content */
import { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Outlet,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Global } from '@emotion/react';
import { RKTheme } from '@robinpowered/design-system';
import { Header } from '@robinpowered/dashboard-apps-header';
import { cssReset } from './cssReset';
import { config } from 'config';
import {
  useAmplitude,
  useAuthContext,
  AmplitudeProvider,
  ApolloContextProvider,
  AuthContextProvider,
  LocalizationContext,
} from 'contexts';
import { i18n } from 'i18n';
import { DocumentHead, GlobalErrorBoundary, PageMessage } from 'components';
import { ConfigProvider, RobinTheme } from '@robinpowered/ui-kit';
import {
  ConfirmDelivery,
  DeliveriesPage,
  DeliveryZonesPage,
  RejectDelivery,
} from 'pages';
import {
  DeliveryDetailsContextProvider,
  DeliveryZonesContextProvider,
  DeliveryFormContextProvider,
  DeliveryEditContextProvider,
  DeliveriesTableContextProvider,
} from 'pages/DeliveriesPage/contexts';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { UpgradePlan } from 'pages/UpgradePlan';

export const App = (): JSX.Element => {
  return (
    <I18nextProvider i18n={i18n}>
      <GlobalErrorBoundary>
        <Global styles={cssReset} />
        <RKTheme>
          <ConfigProvider theme={RobinTheme}>
            <BrowserRouter>
              <ApolloContextProvider>
                <AuthContextProvider>
                  <AmplitudeProvider>
                    <LocalizationContext>
                      <HelmetProvider>
                        <DocumentHead />
                        <RootRouteTree />
                      </HelmetProvider>
                    </LocalizationContext>
                  </AmplitudeProvider>
                </AuthContextProvider>
              </ApolloContextProvider>
            </BrowserRouter>
          </ConfigProvider>
        </RKTheme>
      </GlobalErrorBoundary>
    </I18nextProvider>
  );
};

const RootRouteTree = (): JSX.Element => {
  const { t } = useTranslation('common');
  const { loading, isLoggedIn, error, currentOrgHasVMLicenses } =
    useAuthContext();
  const isDeliveryConfirmationLink = window.location.pathname.includes(
    '/deliveries/confirm'
  );
  const isDeliveryRejectionLink =
    window.location.pathname.includes('/deliveries/reject');
  const isUnauthedLink = isDeliveryConfirmationLink || isDeliveryRejectionLink;

  useEffect(() => {
    const redirectToDashboard =
      !loading &&
      config.env !== 'development' &&
      !isLoggedIn &&
      !isUnauthedLink &&
      process.env.NODE_ENV !== 'development';

    if (redirectToDashboard) {
      window.location.href = config.dashboardUrl;
    }
  }, [loading, isLoggedIn, isUnauthedLink]);

  if (!loading && !isLoggedIn && !isUnauthedLink) {
    return <PageMessage title={t('errors.auth_error.title')} />;
  }

  if (!loading && error && !isUnauthedLink) {
    return (
      <PageMessage
        title={t('errors.auth_error.title')}
        message={t('errors.auth_error.message')}
      />
    );
  }

  if (!loading && isDeliveryConfirmationLink) {
    return <ConfirmDelivery />;
  }

  if (!loading && isDeliveryRejectionLink) {
    return <RejectDelivery />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<PageMessage title={t('errors.missing_slug')} />}
      />
      <Route
        path="/"
        element={
          <>
            <HeaderWithParam />
            <Outlet />
          </>
        }
      >
        {/* TODO: Add the billing check and splash page here */}
        <Route
          path=":orgSlug/deliveries"
          element={
            !currentOrgHasVMLicenses ? <UpgradePlan /> : <DashboardLayout />
          }
        >
          <Route
            index
            element={
              <DeliveryDetailsContextProvider>
                <DeliveryEditContextProvider>
                  <DeliveryFormContextProvider>
                    <DeliveriesTableContextProvider>
                      <DeliveryZonesContextProvider>
                        <DeliveriesPage />
                      </DeliveryZonesContextProvider>
                    </DeliveriesTableContextProvider>
                  </DeliveryFormContextProvider>
                </DeliveryEditContextProvider>
              </DeliveryDetailsContextProvider>
            }
          />
        </Route>
        <Route
          path=":orgSlug/deliveries/delivery-zones"
          element={<DashboardLayout />}
        >
          <Route
            index
            element={
              <DeliveryZonesContextProvider>
                <DeliveryZonesPage />
              </DeliveryZonesContextProvider>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};

const DashboardLayout = (): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 'calc(100vh - 56px)',
      }}
    >
      <Sidebar />
      <Outlet />
    </div>
  );
};

const HeaderWithParam = (): JSX.Element => {
  const { t } = useTranslation('common');
  const { orgSlug } = useParams<{ orgSlug: string }>();
  const { trackEvent } = useAmplitude();
  const { loading, isLoggedIn, setOrgSlug, error } = useAuthContext();

  useEffect(() => {
    setOrgSlug(orgSlug);
  }, [orgSlug, setOrgSlug]);

  useEffect(() => {
    const redirectToDashboard =
      !loading && config.env !== 'development' && !isLoggedIn;

    if (redirectToDashboard) {
      window.location.href = config.dashboardUrl;
    }
  }, [loading, isLoggedIn]);

  if (!loading && !isLoggedIn) {
    return <PageMessage title={t('errors.auth_error.title')} />;
  }

  if (!loading && error) {
    return (
      <PageMessage
        title={t('errors.auth_error.title')}
        message={t('errors.auth_error.message')}
      />
    );
  }

  return (
    <Header
      activeOrgSlug={orgSlug ?? ''}
      amplitudeEventHandler={trackEvent}
      appConfig={{
        featureFlagUrl: config.featureFlagUrl,
        sessionCookieName: config.sessionCookieName,
      }}
    />
  );
};
